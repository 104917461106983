/**
 *  Partial
 *
 *  Helper object to handle Mustache.js partials
 *
 *  @requires   mustache.js
 *
 *  @author Timothy Beard   <tb@webpopulation.com>
 *  @since  2016-07-13
 *  @param  void
 */
var Partial = (function (tmpl_engine) {

    "use strict";

    var err = { illegal_arg_count : "Partial constructor takes 2 to 4 arguments. %d argument(s) provided." };

    /**
     *  Object constructor
     *
     *  Inititates Partial handler and gets all the required data
     *
     *  @access public
     *
     *  @author Timothy Beard   <tb@webpopulation.com>
     *  @since  2016-07-13
     *  @param  String      target      target element's id
     *  @param  String      data        data provider URL
     *  @param  String      template    template container ID
     *  @param  Function    Callback    callback function reference
     */
    var obj = function (target, data, tmpl, callback) {

        var output = false;

        if (tmpl === undefined) {

            tmpl = target;
        }

        if (arguments.length < 2 || arguments.length > 4) {

            console.error(err.illegal_arg_count, arguments.length);
        } else {

            this.target = document.getElementById(target);

            this.data_path = data;
            this.tmpl_path = tmpl;

            if (this.target) {

                this.view_data = this.getData(this.data_path);
                this.view_tmpl = this.getTmpl(this.tmpl_path);

                if (!!callback && callback.constructor === Function) {

                    this.onComplete = callback;
                }
            }

            output = this;
        }

        return output;
    };

    /**
     *  Loaded file handler
     *
     *  Puts file contents into template property
     *
     *  @access private
     *
     *  @author Timothy Beard   <tb@webpopulation.com>
     *  @since  2016-07-13
     *  @param  XMLHttpRequest  req request object to parse
     */
    var onStateChange = function (req, path) {

        if (req.readyState === 4 && req.status === 200) {

            var resp = req.responseText.trim();

            if (path === this.data_path) {

                this.view_data = JSON.parse(resp);
            } else {

                this.view_tmpl = resp;
            }

            this.onDataLoaded();
        }
    };

    /**
     *  External template loader
     *
     *  Gets external template content as text to be passed
     *  to the template engine
     *
     *  @access public
     *
     *  @author Timothy Beard   <tb@webpopulation.com>
     *  @since  2016-07-13
     *  @param  String  path    path to template file
     */
    obj.prototype.getData = function (path) {

        var req = new XMLHttpRequest();

        req.addEventListener("readystatechange", onStateChange.bind(this, req, path));

        req.open("GET", path, true);
        req.send(null);
    };

    obj.prototype.getTmpl = function (path) {

        var tmpl = document.getElementById(path);

        if (tmpl) {

            tmpl = tmpl.innerHTML.trim();
        }

        return tmpl;
    };

    /**
     *  Loaded template handler
     *
     *  Renders partial to target element if view_data is also loaded
     *
     *  @access public
     *
     *  @author Timothy Beard   <tb@webpopulation.com>
     *  @since  2016-07-13
     *  @param  void
     */
    obj.prototype.onDataLoaded = function () {

        if (!this.view_tmpl) {

            this.view_tmpl = this.getTmpl(this.tmpl_path);
        }

        this.render = tmpl_engine.render(this.view_tmpl, this.view_data);

        if (!this.onComplete) {

            this.target.innerHTML = this.render;
        } else {

            this.onComplete();
        }
    };

    obj.prototype.refresh = function () {

        this.getData(this.data_path);
    };

    // Exposes obj as window.Partial
    return obj;
}(Mustache));
