(function () {

	"use strict";

	function startsWith(char) {

		var start = this.substr(0, char.length);

		return (start === char);
	}

	if (String.prototype.startsWith === undefined) {

		Object.defineProperty(String.prototype, "startsWith", { value : startsWith });
	}
}());
